import "./App.css";
import { React, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { reactPlugin } from "./components/AppInsightsConfig";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";

const Application = lazy(() => import("./pages/Application"));
const Home = lazy(() => import("./pages/Home"));
const Statistics = lazy(() => import("./pages/Statistics"));

function App() {
  return (
    <AppInsightsErrorBoundary
      appInsights={reactPlugin}
    >
      <div className="App">
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<>...</>}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="/application"
              element={
                <Suspense fallback={<>...</>}>
                  <Application />
                </Suspense>
              }
            />
            <Route
              path="/statistics"
              element={
                <Suspense fallback={<>...</>}>
                  <Statistics />
                </Suspense>
              }
            />
          </Routes>
        </Router>
      </div>
    </AppInsightsErrorBoundary>
  );
}

export default App;
